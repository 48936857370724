<template>
  <div id="newFooter">
    <div class="container-fluid footer-wrapper">
      <div class="row rowcontainer">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 back-to-pagetop">
          <a @click="goToPageTop()" href="javascript:void(0)">
            <i class="fa fa-arrow-circle-o-up" aria-hidden="true"></i> Top of
            Page
          </a>
        </div>
      </div>
    </div>
    <div class="container footer-wrapper">
      <div class="row rowcontainer">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 footer-top-section">
          <div class="row rowcontainer">
            <div class="col-lg-4 col-md-4 col-sm-4 contact-section">
              <h2>Cochchi.lk</h2>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
              <div class="address-wrapper">
                <div class="address-icon">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                </div>
                <div class="address-content">
                  5th Floor,
                  <br />CWE Secretariat Building,
                  <br />Vauxhall Street,
                  <br />Colombo 02,
                  <br />Sri lanka.
                </div>
              </div>
              <div class="email-wrapper">
                <div class="email-icon">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </div>
                <div class="email-content">orders@lankasathosa.org</div>
              </div>
              <div class="phone-wrapper">
                <div class="phone-icon">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div class="phone-content">011-2307512</div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 footer-navlinks">
              <h2>Useful Links</h2>
              <ul>
                <li v-if="this.$store.state.buyer_accessToken !== null">
                  <a @click="handleProfile">
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i> My
                    Account
                  </a>
                </li>
                <li v-if="this.$store.state.buyer_accessToken === null" class="log login">
                  <a v-on:mouseup="loginNew" class="link-lg">
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i> My
                    Account
                  </a>
                </li>
                <li>
                  <a @click="navigateToAboutUs">
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i> About
                    Us
                  </a>
                </li>
                <li>
                  <a @click="navigateToContactUs">
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="./PrivacyPolicy.html" target="_blank">
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
                    Privacy Policy
                  </a>
                  <!-- <a @click="openPolicy">
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i> Privacy Policy
                  </a>-->
                </li>
                <li>
                  <a href="./TermsAndConditions.html" target="_blank">
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i> Terms
                    And Conditions
                  </a>
                  <!-- <a @click="openTerms">
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i> Terms
                    And Conditions
                  </a>-->
                </li>
                <li>
                  <a href="https://www.lankasathosa.lk/" target="_blank">
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
                    Corporate Website
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-5 newsletter-wrapper">
              <h2>Newsletter Subscribe</h2>
              <!-- Begin Mailchimp Signup Form -->
              Subscribe to find out about our latest online promotions, mouthwatering culinary adventures, lifestyle tips and much more.
              <div id="mc_embed_signup">
                <!-- <form
                  action="https://lankasathosa.us18.list-manage.com/subscribe/post?u=ea8c031f2e213143a959f1ff0&amp;id=5c45a7f83a"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  class="validate"
                  target="_blank"
                  novalidate
                >-->
                <div id="mc_embed_signup_scroll" style="margin-left: -15px">
                  <div class="form-group">
                    <input
                      style="width: 100%;"
                      type="email"
                      value
                      name="EMAIL"
                      class="email form-control"
                      id="mce-EMAIL"
                      placeholder="email address"
                      required
                    />
                  </div>

                  <div style="position: absolute; left: -5000px;" aria-hidden="true">
                    <input
                      type="text"
                      name="b_ea8c031f2e213143a959f1ff0_5c45a7f83a"
                      tabindex="-1"
                      value
                    />
                  </div>

                  <!-- <div class="clear">
                  <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="btn btn-danger"></div>-->

                  <div class="newsletter-input-block">
                    <div class="clear">
                      <button
                        @click="subscribe"
                        type="submit"
                        class="btn btn-danger subscribe-btn"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                      >Subscribe</button>
                    </div>
                  </div>
                </div>
                <!-- </form> -->
              </div>

              <!--End mc_embed_signup-->

              <!-- <div id="mc_embed_signup">
                            <form action="https://lankasathosa.us18.list-manage.com/subscribe/post?u=ea8c031f2e213143a959f1ff0&amp;id=5c45a7f83a" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                <div id="mc_embed_signup_scroll">
                                
                                    <div class="newsletter-input-block">
                                        <div class="form-group">
                                           
                                            <input  class="form-control email" type="email" value="" name="EMAIL" id="mce-EMAIL" placeholder="email address" required>
                                        </div>
                                        <div style="position: absolute; left: -5000px;" aria-hidden="true">
                                            <input type="text" name="b_ea8c031f2e213143a959f1ff0_5c45a7f83a" tabindex="-1" value="">
                                        </div>
                                        <div class="clear">
                                            <button type="button" class="btn btn-danger" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" >Subscribe</button>
                                        </div>
                                    </div>
                                
                                </div>
                            </form>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <img
      src="https://res.cloudinary.com/persystance-network/image/upload/h_180/v1593688506/slt/portal_user/1593688505930.png"
      style="margin-top: -60px;"
    /> -->
    <div class="container-fluid footer-wrapper">
      <div class="row rowcontainer">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 footer-bottom-section">
          <span class="footer-supiri-logo">
            <img
              class="supiripola-log-in-sathosa"
              src=""
            />
          </span>
          <span class="footer-copyright-text">
            ©Cochchi.lk2021.All rights reserved, Cochchi.lk From SLT-Mobitel, Platform partner Supreme
          </span>
          <span class="footer-slt-logo">
            <img
              class="slt-logo-insathosa"
              :src="this.$store.state.colourLogo"
            />
          </span>
        </div>
      </div>
    </div>
    <BuyerLoginModal ref="active" />
  </div>
</template>
<script>
import UncommonFeatures from "@/components/common/uncommon_features.vue";
import BuyerLoginModal from "@/views/Buyer/BuyerLogin/BuyerLloginModal.vue";
export default {
  name: "BottomFooter",
  components: {
    UncommonFeatures,
    BuyerLoginModal
  },
  data() {
    return {
      userRole: 1
    };
  },
  computed: {},
  methods: {
    navigateToContactUs() {
      this.$router.push("/contact-us");
    },
    navigateToAboutUs() {
      this.$router.push("/about-us");
    },
    openTerms() {
      // window.open("https://drive.google.com/file/d/1UkRyecMnkWK1yVBbTRMBzCXhCx_SEZM3/view?usp=sharing");
      //          var myWindow = window.open("", "MsgWindow", "width=500,height=600");
      //   myWindow.document.write();
      this.$router.push({ name: "TermsAndConditions" });
    },
    subscribe() {
      this.$router.push({ name: "Subscribe" });
    },
    loginNew() {
      this.$refs.active.activetab = 1;
      $("#BuyerLoginModal").modal("show");
      if (this.$refs.active.showforgotpass === true) {
        // hide forget password modal when login
        this.$refs.active.showforgotpass = false;
      }
    },
    init() {
      if (localStorage.getItem("buyer_data")) {
        let user = "";
        user = JSON.parse(localStorage.getItem("buyer_data"));
        if (user.first_name !== null && user.last_name !== null) {
          this.buyer = user.first_name + " " + user.last_name;
        }
        this.userRole = user.user_role != undefined ? user.user_role : "";
      } else {
        this.getLoginInfo();
      }
    },
    handleProfile() {
      if (!localStorage.buyer_data) {
        this.$router.push("/buyer-profile").catch(err => {});
        return;
      }
      if (this.$store.state.buyer_accessToken === null) {
        this.loginNew();
      }
      let user = JSON.parse(localStorage.getItem("buyer_data"));
      this.userRole = user.user_role != undefined ? user.user_role : "";
      if (this.userRole == 2) {
        this.$router.push("/buyer-orders").catch(err => {});
        return;
      } else {
        this.$router.push("/buyer-profile").catch(err => {});
      }
    },
    openPolicy() {
      this.$router.push({ name: "PrivacyPolicy" });
      // window.open("https://drive.google.com/file/d/1wCkyNZ6emXvzdZuNy_yGSmCj6UG3B0py/view?usp=sharing");
    },
    goToPageTop: function() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>
<style scoped>
.rowcontainer {
  margin-left: 0px;
  margin-right: 0px;
}
#newFooter .footer-wrapper {
  height: auto;
  padding-left: 0px;
  padding-right: 0px;
}
#newFooter .footer-wrapper h2 {
  font-size: 18px;
  font-weight: 500;
  color: #212529;
  margin-bottom: 25px;
}
/* Back to top styles */
#newFooter .footer-wrapper .back-to-pagetop {
  min-height: 75px;
  background: #5779ae;
  text-align: center;
  color: #ffffff;
  line-height: 75px;
  font-size: 14px;
}
#newFooter .footer-wrapper .back-to-pagetop a {
  color: #ffffff;
  display: block;
  line-height: 75px;
}
#newFooter .footer-wrapper .back-to-pagetop a:hover {
  text-decoration: none;
  color: #000000;
}
#newFooter .footer-wrapper .back-to-pagetop a:focus {
  text-decoration: none;
  color: #de1a22;
}
/*Footer top styles */
#newFooter .footer-wrapper .footer-top-section {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #212529;
}
#newFooter .footer-wrapper .footer-top-section .footer-navlinks ul {
  list-style: none;
}
#newFooter .footer-wrapper .footer-top-section .footer-navlinks ul li {
  margin-bottom: 5px;
}
#newFooter .footer-wrapper .footer-top-section .footer-navlinks ul li a {
  color: #000000;
}
#newFooter .footer-wrapper .footer-top-section .footer-navlinks ul li a:hover {
  color: #de1a22;
}

#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .address-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .address-wrapper
  .address-icon {
  flex-basis: 5%;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .address-wrapper
  .address-content {
  flex-basis: 95%;
}
#newFooter .footer-wrapper .footer-top-section .contact-section .email-wrapper {
  display: flex;
  margin-top: 12px;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .email-wrapper
  .email-icon {
  flex-basis: 5%;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .email-wrapper
  .email-content {
  flex-basis: 95%;
}
#newFooter .footer-wrapper .footer-top-section .contact-section .phone-wrapper {
  display: flex;
  margin-top: 12px;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .phone-wrapper
  .phone-icon {
  flex-basis: 5%;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .phone-wrapper
  .phone-content {
  flex-basis: 95%;
}
#newFooter .footer-wrapper .footer-top-section .newsletter-wrapper {
  padding-left: 10%;
  padding-bottom: 20px;
  /* background: url("/assets/image/new-footer-bg.png") no-repeat bottom; */
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .newsletter-wrapper
  .newsletter-input-block {
  margin-top: 20px;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .newsletter-wrapper
  .newsletter-input-block
  button {
  margin-top: 10px;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  border-radius: 4px;
}
/*Footer middle styles */
#newFooter .footer-wrapper .footer-middle-section {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -245px;
}
#newFooter .footer-wrapper .footer-middle-section .call-section img {
  display: block;
  width: 25%;
  padding-left: 2%;
  padding-right: 2%;
  /* background: #000000; */
  border-radius: 40px;
  margin: 0 auto;
  text-align: left;
  color: #ffffff;
}
/* #newFooter .footer-wrapper .footer-middle-section .call-section button span{
    display: block;
} */
/* #newFooter .footer-wrapper .footer-middle-section .call-section button span.online-sup{
    font-size: 16px;
    font-weight: 400;
} */
/* #newFooter .footer-wrapper .footer-middle-section .call-section button span.number{
    font-size: 24px;
    font-weight: 800;
    margin-top: 4px;
    margin-bottom: 4px;
} */
/* #newFooter .footer-wrapper .footer-middle-section .call-section button span.call-free{
    font-size: 11px;
    font-weight: 400;
    color: #808080;
} */
#newFooter .footer-wrapper .footer-middle-section .social-media-block {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 15px;
}
#newFooter .footer-wrapper .footer-middle-section .social-media-block .fa {
  font-size: 28px;
  margin-left: 6px;
  margin-right: 6px;
  color: #000000;
}
#newFooter
  .footer-wrapper
  .footer-middle-section
  .social-media-block
  .fa:hover {
  color: #de1a22;
}
#newFooter .footer-wrapper .footer-middle-section .social-media-block a {
  color: #000000;
}
#newFooter .footer-wrapper .footer-middle-section .social-media-block a:hover {
  color: #de1a22;
}
/*Footer bottom styles */
#newFooter .footer-wrapper .footer-bottom-section {
  min-height: 70px;
  background: #222222;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #ffffff;
  line-height: 70px;
  font-size: 14px;
}
#newFooter .footer-wrapper .footer-bottom-section a {
  color: #ffffff;
  font-size: 14px;
}
#newFooter .footer-wrapper .footer-bottom-section a {
  text-decoration: none;
}
.supiripola-log-in-sathosa {
  display: inline-block;
  width: 7.5%;
  margin-left: 5px;
  margin-right: 5px;
}
.slt-logo-insathosa {
  display: inline-block;
  width: 2%;
  margin-left: 5px;
  margin-right: 5px;
}
.subscribe-btn{
  background: #5779ae;
  border: none;
}
.subscribe-btn:hover{
  color: #000000;
}
/* Media queries */
@media screen and (max-width: 1200px) {
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 30%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-content {
    flex-basis: 90%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-content {
    flex-basis: 90%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-content {
    flex-basis: 90%;
  }
  #newFooter .footer-wrapper .footer-top-section .newsletter-wrapper {
    padding-right: 0px;
  }
}
@media screen and (max-width: 991px) {
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 35%;
  }
  .supiripola-log-in-sathosa {
    width: 8%;
  }
  .slt-logo-insathosa {
    width: 2.75%;
  }
}
@media screen and (max-width: 875px) {
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 39%;
  }
}
@media screen and (max-width: 768px) {
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-icon {
    flex-basis: 5%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-content {
    flex-basis: 95%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-icon {
    flex-basis: 5%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-content {
    flex-basis: 95%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-icon {
    flex-basis: 5%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-content {
    flex-basis: 95%;
  }
  #newFooter .footer-wrapper .footer-top-section .contact-section {
    margin-bottom: 35px;
  }
  #newFooter .footer-wrapper .footer-top-section .footer-navlinks {
    margin-bottom: 35px;
  }
  #newFooter .footer-wrapper .footer-top-section .newsletter-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  #newFooter .footer-wrapper .footer-middle-section {
    margin-top: -110px;
  }
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 42%;
    padding-left: 4%;
    padding-right: 4%;
  }
}
@media screen and (max-width: 725px) {
  #newFooter .footer-wrapper .footer-bottom-section {
    line-height: 16px;
    font-size: 13px;
  }
  .footer-supiri-logo {
    display: block;
    padding-top: 25px;
    width: 100%;
  }
  .footer-supiri-logo img {
    width: 30%;
  }
  .footer-slt-logo {
    display: block;
    width: 100%;
  }
  .footer-slt-logo img {
    width: 10%;
    padding-bottom: 20px;
  }
  .footer-copyright-text {
    display: block;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 550px) {
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 50%;
    padding-left: 4%;
    padding-right: 4%;
  }
}
@media screen and (max-width: 425px) {
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-content {
    flex-basis: 90%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-content {
    flex-basis: 90%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-content {
    flex-basis: 90%;
  }
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 50%;
    padding-left: 4%;
    padding-right: 4%;
  }
}
@media screen and (max-width: 375px) {
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 75%;
    padding-left: 7%;
    padding-right: 7%;
  }
}
</style>
