<template>
  <div id="app">
    <div id="wrapper" class="wrapper-fluid banners-effect-3">
    <header id="header" class=" typeheader-1">
    <home-page-top-header v-if="showmainheader" /> 
    <HomePageTopHeaderProfile v-if="!showmainheader"/>
    <inner-page-top-header v-if="innerPageShow"/>
    </header>
    <div class="main-container container">
    <Breadcrumb v-if="showbread"/>
    <ul class="breadcrumb" v-if="showbreadblank">         
    </ul>
    <div id="content">
    <home-page-banner v-if="$route.name === 'Buyer Home'"/>
    <div class="row content-main-w">


    <!-- <div class="col-lg-2 col-md-3 col-sm-12 col-xs-12 content-aside main-left" v-if="showlefnav">
    <home-page-left-nav-bar />
    </div> -->
    <div :class="maincontentcss" >
    <router-view />
    </div>
    </div>
    </div>
    </div>
    <footer class="footer-container typefooter-1" style="margin-top:0px;">
    <!-- <bottom-footer/> -->
    <content-footer />
    </footer>
    </div>
  </div>
</template>
<script>
// Home Page Special
import HomePageTopHeader from '@/components/common/home_page_top_header.vue'
import HomePageTopHeaderProfile from './BuyerDashboard/home_page_top_header.vue'
import HomePageLeftNavBar from '@/components/common/home_page_left_nav_bar.vue'
import HomePageBanner from '@/components/common/home_page_banner.vue'
import Breadcrumb from '@/components/common/breadcrumb.vue'
// For All Pages 
import BottomFooter from '@/components/common/footer.vue'
import ContentFooter from "./MerchantDashboard/ContentFooter.vue";
// For Inner Pages 
import InnerPageTopHeader from '@/components/common/home_page_top_header.vue'
// import InnerPageLeftNavBar from '@/components/common/home_page_left_nav_bar.vue'
export default {
  name: 'app',
  components: {
    HomePageTopHeader,
    HomePageTopHeaderProfile,
    BottomFooter,
    HomePageLeftNavBar,
    HomePageBanner,
    InnerPageTopHeader,
    ContentFooter,
    // InnerPageLeftNavBar,
    Breadcrumb
  },
  data() {
  return {
    homePageShow:false,
    innerPageShow:false,
    showbread: true,
    showbreadblank: false,
    showlefnav: true,
    showmainheader:  true,
    maincontentcss: 'col-lg-10 col-md-12 col-sm-12 col-xs-12 main-right'
  }
  },
  watch:{
    $route (to, from){
      this.BreadcrumbStatus();
      this.LeftsideStatus();  
      this.headerStatus();   
      this.zoomContainerRemove();     
    },
  },
  mounted(){
    this.checkRoute();
    this.BreadcrumbStatus();
    this.LeftsideStatus();
    this.headerStatus(); 
    this.zoomContainerRemove();
    
    

    /* ---------------------------------------------------
      Back to Top
    -------------------------------------------------- */
    $(".back-to-top").addClass("hidden-top");
      $(window).scroll(function () {
      if ($(this).scrollTop() === 0) {
        $(".back-to-top").addClass("hidden-top")
      } else {
        $(".back-to-top").removeClass("hidden-top")
      }
    });

    $('.back-to-top').click(function () {
      $('body,html').animate({scrollTop:0}, 1200);
      return false;
    });
    	
  },
  computed:{
  },
  methods: {
    zoomContainerRemove(){
      $('.zoomContainer').each(function(){
      //if statement here 
      // use $(this) to reference the current div in the loop
      //you can try something like...
      $(this).remove();
      });
    },
     checkRoute() {
      if(this.$route.name === 'Home'){
        this.homePageShow = true;
      } else {
        this.homePageShow = false;
      }      
     },
     LeftsideStatus(){
       if(["product", "buyer login", "buyer register", "Buyer Cart", "Buyer Checkout", "category", "Flash Deal", "Product Search", "About Us", "Contact Us" ].includes(this.$route.name)){
          this.showlefnav = false;   
          this.maincontentcss = 'col-lg-12 col-md-12 col-sm-12 col-xs-12';
          // if(this.$route.name === 'product'){
          //   this.maincontentcss = 'col-lg-12 col-md-12 col-sm-12 col-xs-12';
          // } else {
          //   this.maincontentcss = 'col-lg-10 col-md-9 col-sm-8 col-xs-12 main-right';
          // } 
        } else {
          this.maincontentcss = 'col-lg-12 col-md-9 col-sm-12 col-xs-12';
          this.showlefnav = true; 
        }
     },
     headerStatus(){
       if(["Buyer Home","product", "buyer login", "buyer register", "Buyer Cart", "Buyer Checkout", "category", "Flash Deal", "Product List", "Product Search"].includes(this.$route.name)){
          this.showmainheader = true; 
         
        } else {
          this.showmainheader = false;    
        }
     },
     BreadcrumbStatus(){
        if(["Buyer Home", "buyer login", "buyer register"].includes(this.$route.name)){
          this.showbread = false;    
        } else {
          this.showbread = true; 
        }
     },
     BreadcrumbStore(breadcrumbData){}
   }
}
</script>
<style>
@media (min-width: 768px) {
  .classWell {
    min-height: 80vh;
  }
}
@media (min-width: 1280px) {
  .classWell {
    min-height: 85.6vh
  }
}
@media (min-width: 1440px) {
  .classWell {
    min-height: 76vh;
  }
}
@media (min-width: 1800px) {
  .classWell {
    min-height: 38vh;
  }
}
@media (min-width: 1858px) {
  .classWell {
    min-height: 71vh;
  }
}
@media (min-width: 1880px) {
  .classWell {
    min-height: 38vh;
  }
}
@media (min-width: 2880px) {
  .classWell {
    min-height: 38vh;
  }
}
</style>

